export const Awards = () => {
  return (
    <div id="awards" className="content-section">
      <div className="container">
        <div className="title-wrap-centre">
          <h2>Awards</h2>
        </div>
          <div className="content-card" href="https://dev.to/devteam/congrats-to-the-nylas-challenge-winners-2kic" target="_blank">
            <img
              src="./images/technation.png"
              alt=""
              className="step-image"
              style={{"maxWidth": "200px", "marginBottom": "0"}}
            />
            </div>
            <div className="content-wrapper" style={{"textAlign": "center"}}>
              <h5>
              Exceptional Talent Endorsement
              <br /> 2024
              </h5>
              <p>
                Endorsed by Tech Nation as an Exceptional Talent in Technology, awarded a Global Talent United Kingdom Visa. 
              </p>
            </div> 
        <div className="line" style={{"maxWidth": "40vw", "margin": "0 auto", "marginTop": "30px"}}/>
        <div className="w-layout-grid awards-grid">
        <div className="content-card" href="https://dev.to/devteam/congrats-to-the-nylas-challenge-winners-2kic" target="_blank">
            <img
              src="./images/nylas.png"
              alt=""
              className="step-image"
              style={{"maxWidth": "50%"}}
            />
            <div className="content-wrapper" >
              <h5>
              Open Source AI Hackathon
              <br /> 2024
              </h5>
              <p>
                First Place in the international Hackathon integrating Nylas API, Chat GPT, Google APIs with NextJS, NodeJS, Websockets and Typescript. 
              </p>
            </div>
          </div>
          <div className="line-column">
            <div className="line"></div>
          </div>
          <div className="content-card">
            <img
              src="./images/eventbrite-icon.svg"
              alt=""
              className="step-image"
              style={{"maxWidth": "50%"}}
            />
            <div className="content-wrapper">
              <h5>
                Eventbrite Hackathon
                <br />
                2023
              </h5>
              <p>
                Awarded Second Place in the cross company internal Hackathon
                event. Led a team to develop an innovative solution for
                gamification of the user profile and the interaction with other
                users of the platform.
              </p>
            </div>
          </div>
          <div className="line-column">
            <div className="line"></div>
          </div>
          <div className="content-card">
            <img
              src="./images/rgsoc-logo_white-red-background-square.png"
              alt=""
              className="step-image"
              style={{"maxWidth": "50%"}}
            />
            <div className="content-wrapper">
              <h5>
                Rails Girls Summer of Code <br /> 2018
              </h5>
              <p>
                Awarded a three-month fellowship to work on existing Open Source
                projects and expand my skill set through hands-on development
                and collaboration.
              </p>
            </div>
          </div>
          <div className="line-column">
            <div className="line"></div>
          </div>
          <div className="content-card">
            <img
              style={{maxWidth: "50%" }}
              src="./images/scholarship.png"
              alt=""
              className="step-image"
            />
            <div className="content-wrapper">
              <h5>
                Google Developer Scholarship <br /> 2017
              </h5>
              <p>
                Awarded the Google Developer Scholarship for both Phase I and
                II, encompassing an introductory course and a nine-month
                Nanodegree program in Web and Mobile Development.
              </p>
            </div>
          </div>
        </div>
        <div className="w-layout-grid works-grid">
          <div className="line" id="line-mobile"></div>
        </div>
      </div>
    </div>
  );
};
